import React, { createContext, useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import esMessages from "../i18n/es.json";
import enMessages from "../i18n/en.json";
import caMessages from "../i18n/ca.json";

const messages = {
    es: esMessages,
    en: enMessages,
    ca: caMessages
};

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [locale, setLocale] = useState("es");
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);

        // Solo ejecutamos este código en el cliente, no durante el SSR
        if (typeof window !== "undefined") {
            const savedLocale = localStorage.getItem("locale") ||
                (navigator.language || navigator.userLanguage).split("-")[0];

            if (savedLocale && messages[savedLocale]) {
                setLocale(savedLocale);
            }
        }
    }, []);

    const changeLanguage = (lang) => {
        if (messages[lang]) {
            setLocale(lang);
            if (typeof window !== "undefined") {
                localStorage.setItem("locale", lang);
            }
        }
    };

    // Si aún estamos en SSR, pasamos mensajes vacíos para evitar errores
    const currentMessages = isClient ? messages[locale] : messages.es;

    return (
        <LanguageContext.Provider value={{ locale, changeLanguage }}>
            <IntlProvider locale={locale} messages={currentMessages}>
                {children}
            </IntlProvider>
        </LanguageContext.Provider>
    );
};